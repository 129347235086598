const defaultTheme = require('tailwindcss/defaultTheme')
const debugScreens = require('tailwindcss-debug-screens')
const tailwindForms = require('@tailwindcss/forms')
const tailwindcssPseudoElements = require('tailwindcss-pseudo-elements')
const plugin = require('tailwindcss/plugin')

const CONTAINER_GAP_SMALL = '1.875rem'
const CONTAINER_GAP = '3rem'
const NAVBAR_HEIGHT = '4.5rem'

const customColors = {
	...defaultTheme.colors,
	inherit: 'inherit',
	dark: '#170B41',
	purple: {
		50: '#F2EAFD',
		100: '#EDDEFF',
		200: '#CDABF7',
		300: '#B381F3',
		350: '#9A49FF',
		400: '#914DFC',
		500: '#812DEB',
		900: '#400986'
	},
	blue: {
		50: '#EDF0FC',
		100: '#DADFFF',
		200: '#B8C1F5',
		300: '#95A3F0',
		400: '#7184EB',
		500: '#4E65E6',
		600: '#374FD0',
		900: '#220099'
	},
	pink: {
		100: '#F8D5E2',
		200: '#F2ABC6',
		400: '#E5568D',
		500: '#DE2C70'
	},
	teal: {
		50: '#EAFAFA',
		100: '#D5F6F5',
		200: '#ABEDEA',
		300: '#81E3E0',
		400: '#42D6D0',
		500: '#2DD1CB',
		600: '#14C5AF'
	},
	orange: {
		50: '#FFF6ED',
		100: '#FFEDDA',
		300: '#FFC991',
		500: '#FFA448'
	},
	green: {
		50: '#ECFBF2',
		400: '#50D685',
		500: '#3CD278',
		900: '#006D69'
	}
}

module.exports = {
	future: {
		removeDeprecatedGapUtilities: true,
		purgeLayersByDefault: true,
		defaultLineHeights: true,
		standardFontWeights: true
	},
	purge: {
		content: ['./src/**/*.{js,ts,jsx,tsx,css,scss}']
	},
	important: true,
	theme: {
		container: {
			center: true,
			padding: {
				DEFAULT: CONTAINER_GAP_SMALL,
				md: CONTAINER_GAP
				// sm: '2rem',
				// lg: '4rem',
				// xl: '5rem',
				// '2xl': '6rem',
			}
		},
		extend: {
			fontFamily: {
				primary: ['Lato', ...defaultTheme.fontFamily.sans],
				secondary: ['Mikado', ...defaultTheme.fontFamily.sans]
			},
			width: {
				92: '23rem'
			},
			maxWidth: {
				...defaultTheme.spacing
			},
			maxHeight: {
				...defaultTheme.spacing,
				...defaultTheme.maxWidth
			},
			minWidth: {
				...defaultTheme.spacing,
				...defaultTheme.maxWidth
			},
			minHeight: {
				...defaultTheme.spacing,
				...defaultTheme.maxWidth
			},
			spacing: {
				'container-gap-sm': CONTAINER_GAP_SMALL,
				'container-gap': CONTAINER_GAP,
				'navbar-size': NAVBAR_HEIGHT
			},
			zIndex: {
				'-10': '-10',
				'-20': '-20',
				'-30': '-30',
				'-40': '-40',
				'-50': '-50'
			},
			backgroundSize: {
				'100%': '100%'
			},
			fill: {
				...customColors
			},
			stroke: {
				...customColors
			},
			boxShadow: {
				'fancy-1-sm': '0px 1px 2px rgba(78, 101, 230, 0.24)',
				'fancy-1-md': '0px 4px 6px rgba(78, 101, 230, 0.24)',
				'fancy-2-sm': '0px 1px 2px 0px rgba(129, 45, 235, 0.5)',
				'fancy-2-md': '0px 4px 6px 0px rgba(129, 45, 235, 0.5)'
			},
			letterSpacing: {
				0.5: '0.03em'
			},
			listStyleType: {
				'lower-latin': 'lower-latin',
				'lower-roman': 'lower-roman'
			},
			flex: {
				'item-full': '0 0 100%'
			}
		},
		colors: {
			...customColors
		}
	},
	variants: {
		extend: {
			fill: ['hover', 'focus', 'focus-within', 'group-hover', 'group-focus'],
			stroke: ['hover', 'focus', 'focus-within', 'group-hover', 'group-focus'],
			boxShadow: ['active'],
			rotate: ['first', 'last'],
			backgroundColor: ['active'],
			backgroundClip: ['hover', 'focus'],
			fontFamily: ['hover', 'focus', 'focus-within']
		}
	},
	plugins: [
		debugScreens,
		tailwindForms,
		tailwindcssPseudoElements,
		plugin(({ addUtilities }) => {
			addUtilities(
				{
					'.empty-content': {
						content: "''"
					}
				},
				['before', 'after']
			)
		})
	]
}
