import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { Link } from 'gatsby'

import { theme } from '../../tailwind.config'
import { MainLayout } from '../components'

export default function NotFoundPage() {
	return (
		<>
			<MainLayout>
				<div
					className="container text-center flex flex-col items-center justify-center"
					style={{
						height: `calc(100vh - ${theme.extend.spacing['navbar-size']})`
					}}
				>
					<title>Not found</title>
					<h1 className="text-3xl mb-4">Page not found</h1>
					<p className="mb-2">
						Sorry, we couldn’t find what you were looking for.
					</p>
					<Link to="/" className="text-purple-350">
						Go home
					</Link>
				</div>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
